<template>
  <v-card class="fill-height">
    <v-layout wrap fill-height>
      <v-flex xs12>
        <v-img
          :src="'https://www.ofimania.com/img/Detales/' + id + '.webp'"
          :alt="imagen"
          aspect-ratio="2.5"
        >
          <template v-slot:placeholder>
            <v-layout fill-height align-center justify-center ma-0>
              <v-progress-circular
                indeterminate
                color="primary lighten-2"
              ></v-progress-circular>
            </v-layout>
          </template>
        </v-img>
      </v-flex>

      <v-flex xs12>
        <v-card-title primary-title>
          <v-flex xs12 class="text-center">
            <div class="display-1" v-text="nombre" />
          </v-flex>
          <v-flex
            xs12
            :class="imagen == 'Banner' ? 'text-center' : 'text-left'"
          >
            <span>{{ direccion }}</span>
          </v-flex>
        </v-card-title>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12>
        <v-card-text>
          <v-flex
            xs12
            :class="imagen == 'Banner' ? 'text-center' : 'text-left'"
          >
            <span class="subtitle-1"
              ><ins v-if="imagen != 'Banner'">Horario:</ins> {{ horario }}</span
            >
          </v-flex>
          <v-flex
            xs12
            :class="imagen == 'Banner' ? 'text-center' : 'text-left'"
          >
            <span class="subtitle-2"
              ><ins v-if="imagen != 'Banner'">Teléfono:</ins>
              {{ telefono }}</span
            >
          </v-flex>
          <v-flex
            xs12
            :class="imagen == 'Banner' ? 'text-center' : 'text-left'"
          >
            <span class="subtitle-2"
              ><ins v-if="imagen != 'Banner'">Correo Electrónico:</ins>
              {{ correo }}</span
            >
          </v-flex>
        </v-card-text>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 v-if="imagen != 'Banner'">
        <v-card-actions>
          <v-menu open-on-hover transition="slide-y-transition" offset-y auto>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                Contactar <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item>
                <a
                  target="blank"
                  style="text-decoration: none; color: black"
                  :href="
                    'https://api.whatsapp.com/send?phone=' +
                      whatsapp +
                      '&text=¡Quiero%20conocer%20m%C3%A1s%20sobre%20sus%20productos%20y%20servicios!'
                  "
                >
                  <v-list-item-title>
                    <v-icon color="green">mdi-whatsapp</v-icon> Whatsapp
                  </v-list-item-title>
                </a>
              </v-list-item>
              <v-list-item @click="$router.push('/Contacto/' + correo)">
                <v-list-item-title>
                  <v-icon color="primary">mdi-at</v-icon> Correo Electrónico
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text color="purple" target="blank" :href="maps"
            >Ver en Maps</v-btn
          >
        </v-card-actions>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "Id de la Tienda",
    },
    nombre: {
      type: String,
      default: "Nombre de la Tienda",
    },
    direccion: {
      type: String,
      default: "Dirección de la Tienda",
    },
    horario: {
      type: String,
      default: "Horario de la Tienda",
    },
    telefono: {
      type: String,
      default: "Teléfono de la Tienda",
    },
    correo: {
      type: String,
      default: "Correo de la Tienda",
    },
    whatsapp: {
      type: String,
      default: "WhatsApp de la Tienda",
    },
    maps: {
      type: String,
      default: "Google Maps de la Tienda",
    },
    imagen: {
      type: String,
      default: "Imagen de la Tienda",
    },
  },
};
</script>
