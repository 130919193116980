<template>
  <v-flex text-center my-5 px-3 align-center xs12>
    <span :class="movil ? 'headline' : 'display-2'">{{ titulo }}</span>
    <v-btn
      fab
      top
      right
      color="primary"
      class="ml-12 mb-3"
      v-if="home && !movil"
      @click="$router.push(ruta)"
    >
      <v-icon color="secondary">mdi-redo</v-icon>
    </v-btn>
  </v-flex>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["movil"]),
  },
  props: {
    titulo: {
      type: String,
      default: "Titulo",
    },
    home: {
      type: Boolean,
      default: false,
    },
    ruta: {
      type: String,
      default: "Titulo",
    },
  },
};
</script>

<style lang="scss" scoped></style>
