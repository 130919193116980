<template>
  <v-layout xs12 justify-center align-center>
    <v-img
      class="white--text grey"
      :min-height="movil && home ? 'calc(100vh - 56px)' : null"
      :height="!home ? '35vh' : movil ? null : 'calc(100vh - 64px)'"
      width="auto"
      :src="imagen"
    >
      <v-layout wrap justify-center fill-height align-center>
        <v-flex v-if="!home" xs12 text-center my-4>
          <h1 :class="movil ? 'display-1' : 'display-3'">{{ titulo }}</h1>
        </v-flex>
      </v-layout>
    </v-img>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    titulo: {
      type: String,
      default: "Tituto Header",
    },
    home: {
      type: Boolean,
      default: false,
    },
    imagen: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["movil"]),
  },
  beforeDestroy() {
    document.getElementById("app").scrollIntoView({
      behavior: "smooth",
    });
  },
};
</script>
